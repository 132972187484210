import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import store from "./store/store";
import "./index.css";
import ScreenSizeCheck from "./components/ScreenSizeCheck";
import { AlertProvider } from "./components/Alert/AlertContext";
import theme from "./style/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <ScreenSizeCheck />
      </AlertProvider>
    </ThemeProvider>
    <ToastContainer position="top-right" />
  </Provider>
  // </React.StrictMode>
);
