import styled from "styled-components";

function CustomAlert({ text = "", success, top, right }) {
  return (
    <Wrapper top={top} right={right}>
      <div className="icon">
        <img
          src="https://vedhasa.s3.ap-south-1.amazonaws.com/icons/favicon/favicon-no-background.png"
          alt="favicon"
        />
      </div>
      <p>{text}</p>
      <div className="icon">
        <img
          src="https://vedhasa.s3.ap-south-1.amazonaws.com/icons/favicon/favicon-no-background.png"
          alt="favicon"
        />
      </div>
    </Wrapper>
  );
}

export default CustomAlert;

const Wrapper = styled.div`
  border: 3px dotted ${(props) => props.theme.secondaryColor};
  position: fixed;
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  z-index: 100000;
  max-width: 30%;
  padding: 10px;
  background-color: #191f22;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  > .icon {
    width: 25px;
    height: 25px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  > p {
    width: fit-content;
    text-align: center;
  }

  @media only screen and (max-width: 650px) {
    max-width: 250px;
    position: fixed;
    top: 10%;
  }
`;
