import React, { useState, useEffect } from "react";
import Wrapper from "../style/ManageCategoriesStyle";
import BASE_URL from "../utils/baseUrl";
import axios from "axios";

function ManageCategories() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    name: "",
    image: "",
    description: "",
    subCategories: [],
    superCategories: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategoryFields, setSubCategoryFields] = useState([]);
  const [superCategories, setSuperCategories] = useState([]);

  // Input change handler for category fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  // Image upload handler
  const handleImageUpload = (e) => {
    setNewCategory({ ...newCategory, image: e.target.files[0] });
  };

  // Add subcategory fields dynamically
  const handleAddSubcategoryField = () => {
    setSubCategoryFields([...subCategoryFields, { name: "", description: "" }]);
  };

  // Handle changes for subcategory fields
  const handleSubcategoryFieldChange = (index, field, value) => {
    const updatedFields = [...subCategoryFields];
    updatedFields[index][field] = value;
    setSubCategoryFields(updatedFields);
  };

  // Remove a specific subcategory field
  const handleRemoveSubcategoryField = (index) => {
    const updatedFields = [...subCategoryFields];
    updatedFields.splice(index, 1);
    setSubCategoryFields(updatedFields);
  };

  // Populate subcategories into the new category object
  const populateSubcategories = () => {
    setNewCategory({
      ...newCategory,
      subCategories: subCategoryFields,
    });
  };

  // Edit category handler
  const handleEditCategory = (category) => {
    setEditMode(true);
    setSelectedCategory(category);
    setNewCategory({
      name: category.name,
      image: "",
      description: category.description,
      subCategories: category.subCategories || [],
    });
    setSubCategoryFields(category.subCategories || []);
  };

  // Fetch all categories
  async function fetchCategories() {
    try {
      const { data } = await axios.get(`${BASE_URL}/categories`);
      setCategories(data.data);
    } catch (error) {
      console.log(`Error fetching categories:`, error);
    }
  }

  // Add new category
  async function handleAddCategory(e) {
    e.preventDefault();
    try {
      populateSubcategories();
      const formData = new FormData();
      formData.append("name", newCategory.name);
      formData.append("image", newCategory.image);
      formData.append("superCategory", newCategory.superCategories);
      formData.append("description", newCategory.description);
      formData.append("subCategories", JSON.stringify(subCategoryFields));

      await axios.post(`${BASE_URL}/categories`, formData);

      fetchCategories();
      setNewCategory({
        name: "",
        image: "",
        description: "",
        subCategories: [],
      });
      setSubCategoryFields([]);
    } catch (error) {
      console.log(`Error adding category:`, error);
    }
  }

  // Update existing category
  async function handleUpdateCategory(e) {
    e.preventDefault();
    try {
      populateSubcategories();
      const formData = new FormData();
      formData.append("name", newCategory.name);
      formData.append("description", newCategory.description);
      formData.append("superCategories", newCategory.superCategories);
      if (newCategory.image) formData.append("image", newCategory.image);
      formData.append("subCategories", JSON.stringify(subCategoryFields));
      formData.append("id", selectedCategory._id);
      const { data } = await axios.put(`${BASE_URL}/categories`, formData);

      const updatedCategories = categories.map((cat) =>
        cat._id === data.data._id ? data.data : cat
      );
      setCategories(updatedCategories);
      setEditMode(false);
      setNewCategory({
        name: "",
        image: "",
        description: "",
        subCategories: [],
      });
      setSubCategoryFields([]);
    } catch (error) {
      console.log(`Error updating category:`, error);
    }
  }

  // Delete a category
  async function handleDeleteCategory(id) {
    try {
      await axios.delete(`${BASE_URL}/categories/${id}`);
      fetchCategories();
    } catch (error) {
      console.log(`Error deleting category:`, error);
    }
  }

  async function fetchSuperCategories() {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/categories/fetch-super-category`
      );
      if (data.success) {
        setSuperCategories(data.data);
      }
    } catch (error) {
      console.log(`Error while fetching super categories`, error);
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchSuperCategories();
  }, []);

  return (
    <Wrapper>
      <h1>Manage Categories</h1>
      <form onSubmit={editMode ? handleUpdateCategory : handleAddCategory}>
        <div className="form-group">
          <label htmlFor="name">Category Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={newCategory.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="sup-cat-name">Super Category Name</label>
          <select
            name="sup-cat-name"
            id="sup-cat-name"
            onChange={(e) =>
              setNewCategory({
                ...newCategory,
                superCategories: e.target.value,
              })
            }
          >
            <option value=""></option>
            {superCategories?.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="image">Category Image</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
            accept="image/*"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Category Description</label>
          <input
            type="text"
            id="description"
            name="description"
            value={newCategory.description}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Subcategories</label>
          {subCategoryFields.map((field, index) => (
            <div key={index} className="sub-category-field">
              <input
                type="text"
                placeholder="Subcategory Name"
                value={field.name}
                onChange={(e) =>
                  handleSubcategoryFieldChange(index, "name", e.target.value)
                }
              />
              <input
                type="text"
                placeholder="Subcategory Description"
                value={field.description}
                onChange={(e) =>
                  handleSubcategoryFieldChange(
                    index,
                    "description",
                    e.target.value
                  )
                }
              />
              <button
                type="button"
                onClick={() => handleRemoveSubcategoryField(index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddSubcategoryField}>
            Add Subcategory
          </button>
        </div>
        <button type="submit" className="btn">
          {editMode ? "Update Category" : "Add Category"}
        </button>
      </form>

      <div className="category-list">
        <h2>Existing Categories</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Image</th>
              <th>Subcategories</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id}>
                <td>{category.name}</td>
                <td>
                  {category.image && (
                    <img
                      src={category.image}
                      alt={category.name}
                      className="category-img"
                    />
                  )}
                </td>
                <td className="subcategory-cell">
                  <ul className="subcategory-list">
                    {category.subCategories &&
                    category.subCategories.length > 0 ? (
                      category.subCategories.map((subCategory, index) => (
                        <li key={index}>
                          <strong>Name:</strong> {subCategory.name} <br />
                          <strong>Description:</strong>{" "}
                          {subCategory.description}
                        </li>
                      ))
                    ) : (
                      <li>No subcategories available</li>
                    )}
                  </ul>
                </td>
                <td>
                  <button onClick={() => handleEditCategory(category)}>
                    Edit
                  </button>
                  <button onClick={() => handleDeleteCategory(category._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
}

export default ManageCategories;
