import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    .form-group {
      margin-bottom: 15px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 5px;
      }
      > .sub-category-field {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        gap: 40px;
      }
      > button {
        border: 2px solid orange;
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          background-color: #0056b3;
        }
      }
      > select {
        width: 100%;
        height: 40px;
      }
    }

    .btn {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .category-list {
    h2 {
      margin-bottom: 15px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;

      thead {
        background-color: #007bff;
        color: white;

        tr {
          th {
            padding: 10px;
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ddd;

            .category-img {
              width: 50px;
              height: 50px;
              border-radius: 5px;
              object-fit: cover;
            }

            button {
              margin-right: 5px;
              padding: 5px 10px;
              border: none;
              border-radius: 5px;
              cursor: pointer;

              &:first-of-type {
                background-color: #ffc107;
                color: white;
              }

              &:last-of-type {
                background-color: #dc3545;
                color: white;
              }

              &:hover {
                opacity: 0.9;
              }
            }
            > .subcategory-list {
              > li {
                margin: 10px 0;
                list-style: none;
              }
            }
          }
          > .subcategory-cell {
            width: 50%;
          }
        }
      }
    }
  }
`;

export default Wrapper;
